const IconProfile = () => (
    <svg viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M47.53 22.938c.165 1.075.248 2.187.248 3.309 0 12.197-9.89 22.086-22.087 22.086S3.613 38.443 3.613 26.247c0-12.197 9.89-22.078 22.087-22.078 1.691 0 3.336.193 4.908.552"
            stroke="currentColor"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m37.234 17.267 8.686-7.73-8.088.083L46.977 2"
            stroke="#FBC004"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M25.765 33.664a8.824 8.824 0 1 0 0-17.647 8.824 8.824 0 0 0 0 17.647z"
            stroke="currentColor"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.719 41.553a1 1 0 1 0 1.789.894l-1.79-.894zm15.132-6.93a1 1 0 1 0-.296-1.977l.296 1.977zm15.38 7.32a1 1 0 1 0 1.537-1.28l-1.537 1.28zm-7.008-7.8a1 1 0 0 0-.743 1.858l.743-1.857zm-21.715 8.304c.454-.908 1.909-2.595 4.26-4.213 2.321-1.597 5.424-3.062 9.083-3.61l-.296-1.978c-4.01.6-7.393 2.201-9.92 3.94-2.496 1.718-4.252 3.638-4.916 4.967l1.789.894zm30.26-1.784c-2.49-2.987-5.976-5.491-8.545-6.52l-.743 1.858c2.223.89 5.447 3.178 7.751 5.943l1.537-1.28z"
            fill="currentColor"
        />
    </svg>
);

export default IconProfile;
